@font-face {
  font-family: Sofia\ Pro;
  font-weight: bold;
  src: url(./fonts/Sofia\ Pro/Sofia\ Pro\ Bold\ Az.otf);
}

@font-face {
  font-family: Sofia\ Pro;
  font-weight: normal;
  src: url(./fonts/Sofia\ Pro/Sofia\ Pro\ Regular\ Az.otf);
}

@font-face {
  font-family: Sofia\ Pro;
  font-weight: lighter;
  src: url(./fonts/Sofia\ Pro/Sofia\ Pro\ Light\ Az.otf);
}

body {
  /*background-color: #398787;*//*418c89*/
  /*background-color: #7dbd9a;*/
  /*background-color: #b5d6a8;*//*acd4a8*/
  /*background-color: #e3f0de;*/
  /*background-color: #e3f0de;*/
  font-family: 'Sofia Pro';
  font-size: 15px;
  margin: 0;
  text-align: justify;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #333;
}

h1 {
  color: #398787;
  font-size: 3rem;
}

h2 {
  color: #398787;
  font-size: 2.5rem;;
}

h3 {
  color: #398787;
  font-size: 2rem;;
}

h4 {
  color: #398787;
  font-size: 1.5rem;;
}

h5 {
  color: #398787;
  font-size: 1rem;;
}

a.btn,
a.btn:visited {
    background-color: #398787;
    color: #fff;
    border: 0.1rem #fff solid;
    padding: 1rem;
    font-weight: bold;
    border-radius: 0.5rem;
    margin: 2rem;
    box-sizing: content-box;
    min-width: 9rem;
    display: inline-block;
}

a.btn:hover {
    background-color: #def;
    color: #398787;
}

a.btn:active {
    background-color: #fff;
    color: #398787;
    border: 0.1rem #7dbd9a solid;
}

a {
  text-decoration-line: none;
}

.primary {
  color: #398787;
}

.secondary {
  color: #7dbd9a;;
}

.bg-primary {
  background-color: #b5d6a8;
}

.bg-secondary {
  background-color: #e3f0de;;
}

.text-white {
    color: #fff;
}

.text-black {
    color: #000;
}

.show {
  display: block !important;
}

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.6rem;
  }
  
  h2 {
    font-size: 2.2rem;
  }
  
  h3 {
    font-size: 1.8rem;
  }
  
  h4 {
    font-size: 1.4rem;
  }
  
  h5 {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 2.2rem;
  }
  
  h2 {
    font-size: 1.9rem;
  }
  
  h3 {
    font-size: 1.6rem;
  }
  
  h4 {
    font-size: 1.3rem;
  }
  
  h5 {
    font-size: 1rem;
  }
}