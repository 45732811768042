.contact_1__header {
    text-align: center;
    background-image: url('../../images/contactanos-3.png');
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    padding: 1rem 20rem 9rem 20rem;
}

.contact_1__header h1 {
    padding: 0.5rem 1rem;
    background: #fff;
    border-radius: 1rem;
    margin: 0;
    margin-bottom: 10rem;
}

.contact_1__header_ico {
    font-size: 4rem;
    text-align: center;
    color: #9bb;
}

.contact_1__envelope {
    animation: 800ms infinite alternate contact_1__envelope;
    padding: 2rem;
}

@keyframes contact_1__envelope {
  from {
      transform: scale(1) translate(0, -0.2rem);
  }

  to {
    transform: scale(1.3) translate(0, 0.2rem);
  }
}

.contact_1__phone {
    animation: 80ms infinite alternate contact_1__phone;
    padding: 2rem;
}

@keyframes contact_1__phone {
  from {
      transform: rotate(-5deg);
  }

  to {
    transform: rotate(5deg);
  }
}

.contact_1__at {
    animation: 1s infinite alternate contact_1__at;
    padding: 2rem;
}

@keyframes contact_1__at {
  from {
      transform: rotate3d(0, 1, 0, 90deg)
  }

  to {
    transform: rotate3d(1, 0, 0, 0deg);
  }
}

.contact_1__mobile {
    animation: 10ms infinite alternate contact_1__mobile;
    padding: 2rem;
}

@keyframes contact_1__mobile {
    from {
        transform: rotate(-35deg);
    }
  
    to {
      transform: rotate(-40deg);
    }
}

.contact_1__content > p {
    padding: 0 5rem;
    text-align: center;
}

.contact_1__data_wrap {
    margin: 0 auto;
}

.contact_1__data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5rem;
    text-align: left;
    padding: 0 10rem;
}

.contact_1__data_form {
    margin: 2rem 0 0 auto;
}

.contact_1__data_form > div {
    padding-bottom: 1rem;
}

.contact_1__btn {
    text-align: center;
}

.contact_1__data_center {
    text-align: center;
    margin: 2rem auto 0 0;
}

.contact_1__data input {
    width: 100%;
    border: none;
    background-color: #cdd;
    padding: 0.5rem;
}

.contact_1__social_network {
    text-align: center;
    padding: 0 1rem;
}

.contact_1__social_network li {
    list-style-type: none;
    display: inline;
    padding: 0.5rem;
    font-size: 1rem;
}

.contact_1__social_network .active {
    color: #398787;
}

.contact_1__social_network .inactive {
    color: #bbb;
}

@media (max-width: 1200px) {
    .contact_1__header {
        padding: 0.8rem 10rem 8rem 10rem;
    }

    .contact_1__content > p {
        padding: 0 4rem;
        text-align: center;
    }

    .contact_1__data {
        grid-gap: 3rem;
        padding: 0 8rem;
    }
}

@media (max-width: 992px) {
    .contact_1__header {
        padding: 0.6rem 5rem 7rem 5rem;
    }

    .contact_1__header h1 {
        margin-bottom: 8rem;
    }

    .contact_1__content > p {
        padding: 0 3rem;
        text-align: center;
    }

    .contact_1__data {
        padding: 0 6rem;
    }
}

@media (max-width: 768px) {
    .contact_1__header {
        padding: 0.4rem 2rem 6rem 2rem;
    }

    .contact_1__header_ico {
        font-size: 3rem;
    }

    .contact_1__envelope,
    .contact_1__phone,
    .contact_1__at,
    .contact_1__mobile {
        padding: 1rem 1.5rem;
    }

    .contact_1__header h1 {
        margin-bottom: 6rem;
    }

    .contact_1__content > p {
        padding: 0 2rem;
        text-align: center;
    }

    .contact_1__data {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
        padding: 0 4rem;
    }

    .contact_1__data_form,
    .contact_1__data_center {
        margin: auto;
    }
}

@media (max-width: 576px) {
    .contact_1__header {
        padding: 0.2rem 0 5rem 0;
    }
    
    .contact_1__header_ico {
        font-size: 2rem;
    }

    .contact_1__header h1 {
        margin-bottom: 4rem;
    }

    .contact_1__envelope,
    .contact_1__phone,
    .contact_1__at,
    .contact_1__mobile {
        padding: 0.5rem 1rem;
    }

    .contact_1__content > p {
        padding: 0 1rem;
        text-align: center;
    }
    
    .contact_1__data {
        padding: 0 2rem;
    }
}