.talent_1 h1 {
  background-color: #fff;
  padding: 1rem;
  margin-top: 0.5rem;
  text-align: center;
  margin: 2rem 0;
  border-radius: 1rem;
}

.talent_1__back {
  background-image: url('../../images/01-services.jpg');
}

.talent_1__header {
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
  padding: 2rem 8rem;
}

.talent_1__header_ico {
  padding: 0.5rem 0;
  background-color: #222;
  color: #dee;
  font-size: 5rem;
  text-align: center;
  border-radius: 1rem;
}

.talent_1__header_ico div {
  padding: 0 2rem;
  display: inline;
}

.talent_1__content {
  margin: 0 3rem;
}

ol.gradient-list > li::before, ol.gradient-list > li {
    box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1), 0.25rem 0.25rem 0.25rem rgba(0, 75, 125, 0.1);
}

ol.gradient-list {
    counter-reset: gradient-counter;
    list-style: none;
    margin: 1.75rem 0;
    padding-left: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }

  ol.gradient-list > li {
    background: #ddd;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    counter-increment: gradient-counter;
    margin-top: 1rem;
    min-height: 3rem;
    padding: 1rem 1rem 1rem 3rem;
    position: relative;
  }

  ol.gradient-list > li::before,
  ol.gradient-list > li::after {
    background: #fff;
    border-radius: 1rem 1rem 0 1rem;
    content: "";
    height: 3rem;
    left: -1rem;
    overflow: hidden;
    position: absolute;
    top: -1rem;
    width: 3rem;
  }

  ol.gradient-list > li::before {
    align-items: flex-end;
    content: counter(gradient-counter);
    color: #888;
    display: flex;
    font-size: 1.5rem;
    justify-content: flex-end;
    padding: 0.125em 0.25em;
    z-index: 1;
  }

  ol.gradient-list > li:hover {
    background: #398787;
    color: #fff;
  }

  ol.gradient-list > li:hover::before {
    color: #000;
  }

  ol.gradient-list > li:hover::before,
  ol.gradient-list > li:hover::after {
    background: #e3f0de;
  }

 @media (max-width: 768px) {
  .talent_1__header {
    padding: 2rem 4rem;
  }

  .talent_1__header_ico {
    font-size: 3rem;
  }

  .talent_1__content {
    margin: 0 2rem;
  }

  ol.gradient-list {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .talent_1__header {
    padding: 2rem;
  }

  .talent_1__header_ico {
    color: #cdd;
    font-size: 2rem;
  }
  
  .talent_1__content {
    margin: 0 1rem;
  }

  ol.gradient-list {
      grid-template-columns: repeat(1, 1fr);
  }
}