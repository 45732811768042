.footer_1 {
    padding: 0.5rem;
    position: relative;
    z-index: 4;
    background-color: #001008;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0rem;
    color: rgb(120, 128, 120);
    text-align: left;
}

.footer_1 > div {
    padding: 1em 0;
    margin: 0 0.75em;
}

.footer_1__secciones h2 {
    color: rgb(100, 130, 80);
    font-size: 1.5em;
    margin: 0 0 0.3em;
}

.footer_1__secciones div a {
    color: rgb(130, 148, 130);
}

.footer_1__secciones div a:active,
.footer_1__secciones div a:hover {
    color: rgb(170, 200, 170);
}

@media (max-width: 1024px) {
    .footer_1 > div {
        padding: 0.75em 0;
        margin: 0 0.75em;
    }

    .footer_1 {
        grid-template-columns: repeat(3, 2fr);
    }
}

@media (max-width: 576px) {
    .footer_1 > div {
        padding: 0.75em 0;
        margin: 0 0.75em;
    }

    .footer_1 {
        grid-template-columns: repeat(2, 3fr);
    }
}