.psychometric_test_1 h1 {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 0.5rem;
  text-align: center;
  margin: 2rem 0;
}

.psychometric_test_1__back {
  background-image: url('../../images/01-psicometricas.jpg');
}

.psychometric_test_1__header {
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
  padding: 2rem 8rem;
}

.psychometric_test_1__header_ico {
  padding: 1rem;
  color: #222;
  display: inline-block;
  font-size: 5rem;
  text-align: center;
}

.psychometric_test_1__header_ico div {
  padding: 0 2rem;
  display: inline;
}

.psychometric_test_1_why {
  background-image: url("../../images/01test.jpg");
  background-size: 10rem 10rem;
  background-position-y: center;
  background-repeat: no-repeat;
  padding: 5rem 2rem 5rem 13rem;
  background-color: #eee;
}

.psychometric_test_1__content {
  margin: 0 3rem;
}

 @media (max-width: 768px) {
  .psychometric_test_1__header {
    padding: 2rem 4rem;
  }

  .psychometric_test_1__header_ico {
    font-size: 3rem;
  }

  .psychometric_test_1__content {
    margin: 0 2rem;
  }
}

@media (max-width: 576px) {
  .psychometric_test_1__header {
    padding: 2rem;
  }

  .psychometric_test_1__header_ico {
    color: #cdd;
    font-size: 2rem;
  }
  
  .psychometric_test_1__content {
    margin: 0 1rem;
  }
}