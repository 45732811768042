.navbar_3 {
    background-color: #fff;
    display: none;
}

.navbar_3__head {
    display: inline-block;
    padding-left: 1.5rem;
}

.navbar_3__button {
    display: inline-block;
    padding-right: 1.5rem;
    right: 0;
    position: absolute;
    font-size: 1.5rem;
}

.navbar_3 .navbar_3__button_clicked a svg {
    color: #7dbd9a;
}

.navbar_3__button div {
    margin: 0 auto;
    padding: 0.7rem 0;
}

.navbar_3 a {
    cursor: pointer;
}

.navbar_3 a svg {
    color: #398787;
}

.navbar_3__logo {
    padding: 0.5rem 0;
    text-align: center;
    width: 4rem;
}

.navbar_3__wrap {
    display: inline-block;
    position: absolute;
    right: 0;
    padding-right: 3rem;
 }

.navbar_3__nav {
    margin: 0 auto;
    padding: 0;
    border: 0.1rem solid #398787;
    display: none;
}

.navbar_3__nav ul {
    margin: 0;
    padding: 0;
}

.navbar_3__nav > div {
    margin: 0 auto;
    padding: 0;
}

.navbar_3__nav > div > ul {
    margin: 0 auto;
    padding: 0;
    display: table;
}

.navbar_3__nav li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navbar_3__nav li a {
    display: block;
}

.navbar_3__nav li:focus > a,
.navbar_3__nav li:active > a,
.navbar_3__nav li:hover > a {
    color: #398787;
    transition: all ease-in 100ms;
}

.navbar_3__nav > ul > li {
    border-bottom: 0.1rem solid #9ab;
}

.navbar_3__nav > ul > li:last-child {
    border-bottom: none;
}

.navbar_3__nav li > a {
    font-weight: bold;
}

.navbar_3__nav a {
    color: #398787;
    cursor: pointer;
    padding: 0.5rem 1rem;
    text-decoration-line: none;
    transition: all ease-out 100ms;
}

.navbar_3__sub > a {
    cursor: default;
}

.navbar_3__sub:focus .navbar_3__subitems,
.navbar_3__sub:active .navbar_3__subitems, 
.navbar_3__sub:hover .navbar_3__subitems {
    display: block;
    height: auto;
}

.navbar_3__subitems {
    border: solid 0 transparent;
    display: none;
    height: 0;
    padding: 0;
}

.navbar_3__subitems li a {
    margin-left: 2rem;
}

.navbar_3__nav div ul > li > ul.navbar_3__subitems > li {
    display: list-item;
    list-style-type: disc;
    padding: 0.5rem 0;
    text-align: left;
    border-right: none;
}

ul.navbar_3__subitems > li > a,
ul.navbar_3__subitems > li > a:visited {
    color: #398787;
    transition: all ease-in 100ms;
}

ul.navbar_3__subitems li:focus,
ul.navbar_3__subitems li:active,
ul.navbar_3__subitems li:hover,
.navbar_3__without_subitems:focus,
.navbar_3__without_subitems:active,
.navbar_3__without_subitems:hover {
    background-color: #8ba;
}

ul.navbar_3__subitems li:focus a,
ul.navbar_3__subitems li:active a,
ul.navbar_3__subitems li:hover a,
.navbar_3__without_subitems:focus a,
.navbar_3__without_subitems:active a,
.navbar_3__without_subitems:hover a {
    color: #f0fff8!important;
    transition: all ease-in 100ms;
}

@media (max-width: 768px) {
}

@media (max-width: 440px) {
    .navbar_3 {
        display: block;
    }
}