.whywe_1__header {
    padding: 3rem auto;
}

.whywe_1__content {
    padding: 4rem;
}

@media (max-width: 992px) {
    .whywe_1__content {
        padding: 3rem;
    }
}
@media (max-width: 768px) {
    .whywe_1__content {
        padding: 2rem;
    }
}

@media (max-width: 576px) {
    .whywe_1__content {
        padding: 1rem;
    }
}