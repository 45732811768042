
footer {
    min-height: 3rem;
    padding: 1rem 2rem 0.5rem;
    position: relative;
    z-index: 4;
}

.footer_rights {
    text-align: center;
}

.footer__net {
    text-align: center;
    color: #398787;
}

.footer__net ul{
    text-align: center;
    padding: 0 1rem;
}

.footer__net li {
    list-style-type: none;
    display: inline;
    padding: 1rem;
    font-size: 1.3rem;
}

.footer__net li a.active > svg,
.footer__net li a:visited > svg{
    color: #398787;
    transition: all ease-out 500ms;
}

.footer__net li a.active:hover > svg {
    transform: rotate(1turn);
    transition: all ease-in 500ms;
}

.footer__net li a.inactive > svg {
    color: #7b7b7b;
}
