.about_us_1 {

}

.about_us_1 h1 {
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 0.5rem;
    text-align: center;
    margin: 2rem 0;
}

.about_us_1__back {
  background-image: url('../../images/02-quienes-somos.jpg');
}

.about_us_1__header {
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
  padding: 1rem 8rem 6rem 8rem;
  text-align: center;
}

.about_us_1__content {
  margin: 0 3rem;
}

.about_us_1__columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 4rem;
}

.about_us_1__we_are {
    background-color: #eeeef0;
    text-align: center;
    padding: 1rem 0;
}

.about_us_1__we_are h2,
.about_us_1__we_are h5 {
    color: #122;
}

.about_us_1__logo {
    padding: 1rem;
    text-align: center;
    width: 20rem;
}

@media (max-width: 768px) {
    .about_us_1__content {
        margin: 0 2rem;
    }

    .about_us_1__columns {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0rem;
    }
}
@media (max-width: 576px) {
    .about_us_1__content {
        margin: 0 1rem;
    }
}