.home_1 {
    color: #398787;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    padding: 0 2rem;
}

.home_1__back_1 {
    background-image: url('../../images/01-home.jpg');
}

.home_1__back_2 {
    background-image: url('../../images/02-home.jpg');
}

.home_1__back_3 {
    background-image: url('../../images/03-home.jpg');
}

.home_1__back_4 {
    background-image: url('../../images/04-home.jpg');
}

.home_1__back_5 {
    background-image: url('../../images/05-home.jpg');
}

.home_1__back_6 {
    background-image: url('../../images/06-home.jpg');
}

.home_1__back_7 {
    background-image: url('../../images/07-home.jpg');
}

.home_1_content {
    margin: 0 auto;
    padding: 3rem 0;
    text-align: center;
    width: 70%;
}

.home_1_title {
    text-shadow: 0 0 2px #000;
}

.home_1__form  {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media (max-width: 768px) {
    .home_1_content {
        padding: 2rem 1rem;
    }
}

@media (max-width: 576px) {
    .home_1 {
        padding: 0;
    }

    .home_1_content {
        width: 80%;
    }
}

@media (max-width: 440px) {
    .home_1_content {
        width: 90%;
    }
}