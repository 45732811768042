.navbar_2 {
    background-color: #fff;
}

.navbar_2__head {
    display: inline-block;
    padding-left: 3rem;
}

.navbar_2__logo {
    padding: 0.5rem;
    text-align: center;
    width: 4rem;
}

.navbar_2__wrap {
    display: inline-block;
    position: absolute;
    right: 0;
    padding-right: 3rem;
}

.navbar_2__nav {
    margin: 0 auto;
    padding: 1rem 0;
}

.navbar_2__nav > div {
    margin: 0 auto;
    padding: 0;
}

.navbar_2__nav > div > ul {
    margin: 0 auto;
    padding: 0;
    display: table;
}

.navbar_2__nav li {
    float: left;
    list-style-type: none;
    margin: 0;
}

.navbar_2__nav li:focus > a,
.navbar_2__nav li:active > a,
.navbar_2__nav li:hover > a {
    color: #398787;
    transition: all ease-in 100ms;
}

.navbar_2__nav div ul > li {
    border-right: 0.1rem solid #000;
}

.navbar_2__nav div ul > li:last-child {
    border-right: none;
}

.navbar_2__nav li > a {
    font-weight: bold;
}

.navbar_2__nav a {
    color: #398787;
    cursor: pointer;
    padding: 1rem;
    text-decoration-line: none;
    transition: all ease-out 100ms;
}

.navbar_2__sub > a {
    cursor: default;
}

.navbar_2__sub:focus .navbar_2__subitems,
.navbar_2__sub:active .navbar_2__subitems, 
.navbar_2__sub:hover .navbar_2__subitems {
    border: solid 1px #555;
    display: block;
    height: auto;
}

.navbar_2__subitems {
    background-color: #484848;
    border: solid 0 transparent;
    display: none;
    height: 0;
    margin-top: 0.5rem;
    padding: 0;
    position: absolute;
}

.navbar_2__nav div ul > li > ul.navbar_2__subitems > li {
    display: list-item;
    float: none;
    list-style-type: none;
    padding: 0.5rem 0;
    text-align: left;
    border-right: none;
    border-bottom: 0.1rem solid #555;
}

.navbar_2__nav div ul > li > ul.navbar_2__subitems > li > a,
.navbar_2__nav div ul > li > ul.navbar_2__subitems > li > a:visited {
    color: #bbb;
    transition: all ease-in 100ms;
}

.navbar_2__nav div ul > li > ul.navbar_2__subitems li:focus a,
.navbar_2__nav div ul > li > ul.navbar_2__subitems li:active a,
.navbar_2__nav div ul > li > ul.navbar_2__subitems li:hover a {
    color: #f0fff8;
    transition: all ease-in 100ms;
}

.navbar_2__social_network > div > a > svg {
    padding-left: 2rem;
    transition: all ease-out 500ms;
}

.navbar_2__social_network a {
    color: #777;
}

.navbar_2__social_network:focus > div > a > svg,
.navbar_2__social_network:active > div > a > svg,
.navbar_2__social_network:hover > div > a > svg {
    font-size: 3.2rem;
    padding: 0;
    color: rgb(10, 102, 194);
    margin-top: -1rem;
}

@media (max-width: 768px) {
    .navbar_2__head {
        padding-left: 2rem;
    }

    .navbar_2__wrap {
        padding-right: 2rem;
     }
}

@media (max-width: 576px) {
    .navbar_2__head {
        padding-left: 0;
    }

    .navbar_2__wrap {
        padding-right: 0;
    }

    .navbar_2__nav a {
        padding: 1rem 0.5rem;
    }
}

@media (max-width: 440px) {
    .navbar_2 {
        display: none;
    }
}