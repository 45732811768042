.misionvisionvalores_1 {
}

.misionvisionvalores_1_header {
    text-align: center;
    background-image: url('../../images/01-meta.jpg');
    background-position-x: center;
    background-position-y: top;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 8rem 0;
}

.misionvisionvalores_1__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    padding: 2rem 3rem;
}
  
@media (max-width: 768px) {
    .misionvisionvalores_1_header {
        padding: 7rem 0;
    }

    .misionvisionvalores_1__content {
        grid-template-columns: repeat(1, 1fr);
        padding: 1rem;
    }
}

@media (max-width: 576px) {
    .misionvisionvalores_1_header {
        padding: 6rem 0;
    }
}