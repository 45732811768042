.navbar_1 {
    background-color: #fff;
    margin-bottom: 1.75rem;
}

.navbar_1__head {
    text-align: center;
}

.navbar_1__logo {
    padding: 1rem;
    text-align: center;
    width: 10rem;
}

.navbar_1__wrap {
    float: left;
    width: 50%;
    margin-bottom: 2em;
    position: relative;
 }

.navbar_1__nav {
    left: 25%;
    margin: 0 auto;
    padding: 0 0 2rem 0;
    position: relative;
    width: 150%;
}

.navbar_1__nav > div {
    margin: 0 auto;
    padding: 0;
}

.navbar_1__nav > div > ul {
    margin: 0 auto;
    padding: 0;
    display: table;
}

.navbar_1__nav li {
    float: left;
    list-style-type: none;
    margin: 0;
}

.navbar_1__nav div ul > li {
    border-right: 0.1rem solid #000;
}

.navbar_1__nav div ul > li:last-child {
    border-right: none;
}

.navbar_1__nav li > a,
.navbar_1__nav li > a:visited {
    font-weight: bold;
    transition: all ease-in 100ms;
}

.navbar_1__nav li:focus > a,
.navbar_1__nav li:active > a,
.navbar_1__nav li:hover > a {
    color: rgb(181,214,168);
    transition: all ease-in 100ms;
}

.navbar_1__nav a {
    color: #398787;
    cursor: pointer;
    padding: 1rem;
    text-decoration-line: none;
    transition: all ease-out 100ms;
}

.navbar_1__sub:focus .navbar_1__subitems,
.navbar_1__sub:active .navbar_1__subitems, 
.navbar_1__sub:hover .navbar_1__subitems {
    border: solid 1px #555;
    display: block;
    height: auto;
}

.navbar_1__subitems {
    background-color: #484848;
    border: solid 0 transparent;
    display: none;
    height: 0;
    margin-top: 0.5rem;
    padding: 0;
    position: absolute;
}

.navbar_1__nav div ul > li > ul.navbar_1__subitems > li {
    display: list-item;
    float: none;
    list-style-type: none;
    padding: 0.5rem 0;
    text-align: left;
    border-right: none;
    border-bottom: 0.1rem solid #555;
}

.navbar_1__nav div ul > li > ul.navbar_1__subitems > li > a,
.navbar_1__nav div ul > li > ul.navbar_1__subitems > li > a:visited {
    color: #bbb;;
    transition: all ease-in 100ms;
}

.navbar_1__nav div ul > li > ul.navbar_1__subitems > li:focus > a,
.navbar_1__nav div ul > li > ul.navbar_1__subitems > li:active > a,
.navbar_1__nav div ul > li > ul.navbar_1__subitems > li:hover > a {
    color: #f0fff8;
    transition: all ease-in 100ms;
}

@media (max-width: 768px) {
    .navbar_1__logo {
        width: 8rem;
    }
}

@media (max-width: 576px) {
    .navbar_1__logo {
        width: 5rem;
    }
}

@media (max-width: 440px) {
    .navbar_1 {
        display: none;
    }
}